import React from 'react'
import '../styles/styles.scss'
import Layout from '../components/LayoutLanding'

const success = () => {
  return (
    <Layout>
      <div className="success-wrap">
        <h1>Success</h1>
        <h2>Almost Finished</h2>
        <p>I just need to confirm your email address.</p>
        <p>
          To complete your subscription, please click the link in the email I
          just sent you.
        </p>
      </div>
    </Layout>
  )
}

export default success
